import { Day } from "../../components/day";
import "./style.css";
// import eventData from "../../assets/holidays.json";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Console } from "console";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useHttp } from "../../common/hooks/useHttp";
import env from "../../config";

export interface HolidayEvent {
  date: string;
  title: string;
  content: string;
  country: string;
  event_categories: string;
  event_url?: string;
  hashtags?: string;
  emoji: string;
}

export interface EventData {
  holidays: HolidayEvent[];
}

interface EventsByDay {
  [key: string]: HolidayEvent[];
}

interface EventsByMonth {
  [key: number]: EventsByDay;
}

function Calendar() {
  const [eventsByMonth, setEventsByMonth] = useState<EventsByMonth>({});
  const containerRef = useRef<HTMLDivElement>(null);
  const { sendRequest: getEventData } = useHttp();
  const [eventData, setEventData] = useState<EventData | null>(null);
  const [loading, setLoading] = useState(true);
  const BASE_URL = `${env.API_URL}`;
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );
  const { loc_id, loc_name, name } = useParams();
  // :loc_id/:loc_name/:name
  const checkLocation = async () => {
    // alert(userId, message);
    let config = {
      method: "GET",
      maxBodyLength: Infinity,
      url: `${env.PANDORA_API}/assistants/${loc_id}`,
      headers: {
        'Authorization': `Bearer ${env.PANDORA_AUTH}`,
      },
    };

    const loc = await axios
      .request(config)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          console.log('response?.data?.data',response?.data?.data)
          return {
            success: true,
            status: 200,
          };
        } else {
          setTimeout(() => {
            window.location.href = `https://connect.maybetech.com/?profile_id=${loc_id}&profile_name=${loc_name}&username=${name}&type=calendar`;
          }, 2000)
          return {
            success: true,
            status: 404,
          };
        }
      })
      .catch((error) => {
        window.location.href = `https://connect.maybetech.com/?profile_id=${loc_id}&profile_name=${loc_name}&username=${name}&type=calendar`;
        // setlocValid(false);
        return {
          success: true,
          status: 404,
        };
      });
    return loc;
  };

  useEffect(() => {
    getEventData(
      {
        url: `${BASE_URL}/chat/event-data`,
        method: "GET",
        payload: {},
      },
      async (response: any) => {
        setEventData(response.data as EventData);
        setLoading(false);
        console.log(response);
      }
    );
  }, [])

  const onLoad = async () => {
    const check = await checkLocation();

    if (check.success && eventData && !loading) {
      const organizedData: EventsByMonth = {};
      eventData?.holidays.forEach((event: any) => {
        const date = new Date(event.date);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
          return;
        }

        const month = date.getMonth();
        const day = date.getDate().toString();

        organizedData[month] = organizedData[month] || {};
        organizedData[month][day] = organizedData[month][day] || [];
        organizedData[month][day].push(event);
      });

      setEventsByMonth(organizedData);

      setTimeout(() => {
        const specificElement = document.getElementById("focus");

        if (specificElement) {
          specificElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }, 1000);
    }
    setTimeout(() => {
      const containerElement = containerRef.current;

      // Get the specific element by ID
      const specificElement = document.getElementById("focus");

      // Check if both the container and specific element exist
      if (containerElement && specificElement) {
        specificElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 1000);
  };

  useEffect(() => {
    onLoad();
  }, [eventData]);

  // Get the current year and month
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const renderDays = () => {
    const daysInMonth = new Date(currentYear, selectedMonth + 1, 0).getDate();

    return Array.from({ length: daysInMonth }, (_, i) => {
      const dayDate = new Date(currentYear, selectedMonth, i + 1);
      const day = dayDate.toISOString().split("T")[0];

      const events =
        eventsByMonth[selectedMonth] && eventsByMonth[selectedMonth][i + 1]
          ? eventsByMonth[selectedMonth][i + 1]
          : [];

      return (
        <>
          <Day key={i} date={dayDate} events={events} />
          <hr />
        </>
      );
    });
  };

  const handleMonthClick = (month: number) => {
    setSelectedMonth(month);
  };

  const renderMonthTabs = () => {
    const months = Array.from({ length: 12 }, (_, i) => i);

    return months.map((month) => (
      <div
        key={month}
        className={`monthTabsItem pb-2 ${selectedMonth === month ? "activeTab" : ""
          }`}
        onClick={() => handleMonthClick(month)}
      >
        <h2>
          {new Date(2022, month, 1).toLocaleString("en-US", { month: "short" })}
        </h2>
      </div>
    ));
  };

  return (
    <div className="p-5 m-auto">
      <div className="calendarWrapper" ref={containerRef}>
        <div id="month-bar">
          <h1 className="year mb-5">{currentYear}</h1>
          <div className="monthTabs w-[90%] m-auto flex justify-between">
            {renderMonthTabs()}
          </div>
          <h2 className="fullMonth">
            {new Date(2022, selectedMonth, 1)
              .toLocaleString("en-US", {
                month: "long",
              })
              .toUpperCase()}
            {/* {currentDate.toLocaleString("en-US", { month: "long" }).toUpperCase()} */}
          </h2>
        </div>
        {renderDays()}
      </div>
    </div>
  );
}

export default Calendar;
