import React, { useState } from "react";
import SvgIcon from "../icons";
import "./index.css";
import axios from "axios";
import localforage from "localforage";
import env from "../../../../config";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
}
interface handleProps {
  isModalOpen: boolean;
  closeModal: () => void;
  handleLocation: (data: any) => void;
}
const BASE_URL = env.API_URL;

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const UserModal: React.FC<handleProps> = ({
  isModalOpen,
  closeModal,
  handleLocation,
}) => {
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const payload = {
      username: e.target.username.value,
      loc_name: e.target.loc_name.value,
      email: e.target.email.value,
      role: "demo_user",
      type: 'calendar',
    };
    await localforage.setItem("user_data", payload)
    const user_data = await axios
      .post(BASE_URL + "/auth", payload)
      .then(async function (response) {
        console.log(response.data, "data");
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
    user_data?.data &&
      (await localforage.setItem("user_data", user_data?.data));
    handleLocation(user_data?.data);
    closeModal();
    // console.log(user_data?.data);
  };
  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <SvgIcon id={"starsBlack"} />
        <p className="mb-4 modalText mt-10">
          To use Maybe* AI let us know who you are:{" "}
        </p>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className="flex space-x-4">
            <input
              type="text"
              name="username"
              placeholder="User Name"
              className="flex-1 modalInputs"
            />
            <input
              type="text"
              placeholder="Business Name"
              name="loc_name"
              className="flex-1 modalInputs"
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="Email"
              name="email"
              className="w-full modalInputs"
            />
          </div>
          <button type="submit" className="w-full modalBtn">
            Next
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default UserModal;
