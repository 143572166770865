import Icons from "../../../../assets/icons";

const allIcons = Icons;

const SvgIcon = ({ id, ...props }) => {
  const selectedIcon = allIcons[id];
  return selectedIcon ? selectedIcon(props) : null;
};

export default SvgIcon;
