import {
  Environment
} from "../enums";

const env = {
  API_URL: "http://localhost:8082/api/v1",
  APP_ENV: "DEV",
  PANDORA_API: "https://dev-pandora.maybetech.com/api",
  PANDORA_AUTH: "19e20c56-3355-422d-96d0-97706036ddfd"
};

if (process.env.REACT_APP_ENV === Environment.DEVELOPMENT) {
  env.API_URL = "https://betachatapi.maybetech.com/api/v1";
  env.APP_ENV = "DEV";
  env.PANDORA_API = "https://dev-pandora.maybetech.com/api";
  env.PANDORA_AUTH = "19e20c56-3355-422d-96d0-97706036ddfd";
}

if (process.env.REACT_APP_ENV === Environment.PRODUCTION) {
  env.API_URL = "https://apichat.maybetech.com/api/v1";
  env.APP_ENV = "PROD";
  env.PANDORA_API = "https://pandora.maybetech.com/api";
  env.PANDORA_AUTH = "a71860da-1a58-4797-90f1-02dcb5fd06b1";
}

export default env;