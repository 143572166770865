import { useState } from "react";
import { HolidayEvent } from "../../pages/calendar";
import SvgIcon from "../icons";
import "./style.css";
import { useHttp } from "../../common/hooks/useHttp";
import { useParams } from "react-router-dom";
import env from "../../config";

interface DayProps {
  date: Date;
  events: HolidayEvent[];
}

export const Day: React.FC<DayProps> = ({ date, events }) => {
  const BASE_URL = `${env.API_URL}`;
  const APP_ENV = env.APP_ENV;
  const [loadingEventTitles, setLoadingEventTitles] = useState<{ [key: string]: boolean }>({});
  const [eventContent, setEventContent] = useState<{
    [key: string]: string | null;
  }>({});
  const [tooltipStates, setTooltipStates] = useState<any>({});
  const [showTooltip, setShowTooltip] = useState<number | null>(null);
  const { sendRequest: getEventContent } = useHttp();
  const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" });
  var dayOfMonth = date.getDate();
  const { loc_id, name, email } = useParams();
  const currentDate = new Date().getDate();

  // alert(dayOfMonth);
  const handleRequest = (title: string, date: string) => {
    if (loadingEventTitles[title]) {
      return;
    }

    setLoadingEventTitles((prevLoadingEventTitles) => ({
      ...prevLoadingEventTitles,
      [title]: true,
    }));

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    getEventContent(
      {
        url: `${BASE_URL}/chat/dayevent`,
        method: "POST",
        payload: {
          date: date,
          event_title: title,
          profile_id: loc_id,
          user_name: name,
          env: APP_ENV
        },
      },
      (response: any) => {
        setEventContent((prevContent) => ({
          ...prevContent,
          [title]: response.data,
        }));

        setLoadingEventTitles((prevLoadingEventTitles) => ({
          ...prevLoadingEventTitles,
          [title]: false,
        }));
      }
    )
  };
  const handleSchedule = () => {
    // The URL you want to open in a new tab
    // https://v2.maybetech.com/v2/location/OhgGZGqoLctbecMHw38y/marketing/social-planner/post
    const url = `https://v2.maybetech.com/v2/location/${loc_id}/marketing/social-planner/post`;

    // Open the link in a new tab
    window.open(url, "_blank");
  };
  const copyEventContent = (content: string | null, eventIndex: number) => {
    if (content == null) return false;
    const tempEl = document.createElement("div");
    tempEl.innerHTML = content;

    // Append the temporary element to the body
    document.body.appendChild(tempEl);

    // Create a range and select the content of the temporary element
    const range = document.createRange();
    range.selectNodeContents(tempEl);

    // Remove any current selections and add the new range
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    // Copy the selected content to the clipboard
    try {
      document.execCommand("copy");
      setTooltipStates((prevStates: any) => ({
        ...prevStates,
        [eventIndex]: "Copied!",
      }));
    } catch (err) {
      console.error("Failed to copy: ", err);
    }

    // Clear the selection and remove the temporary element
    selection?.removeAllRanges();
    tempEl.remove();

    setTimeout(() => {
      setTooltipStates((prevStates: any) => ({
        ...prevStates,
        [eventIndex]: "Copy!",
      }));
    }, 1500);
  };

  const handleBoxClick = (title: string, date: string) => {
    if (eventContent[title] === undefined) {
      handleRequest(title, date);
    }
  };

  return (
    <div className="mt-8 flex flex-wrap">
      <div
        className="dayDate flex flex-col items-start mb-4 w-[5%]"
        id={currentDate.toString() == dayOfMonth.toString() ? "focus" : ""}
      >
        <h2>{dayOfMonth}</h2>
        <p>{dayOfWeek.toUpperCase()}</p>
      </div>
      <div className="dayEvents w-[95%]">
        {events.length > 0 ? (
          <div className="flex flex-wrap w-full">
            {events.map((event, index) => (
              <div key={index} className="w-full md:w-1/3 mb-4 p-5 pt-0 pl-0">
                <div className="eventBoxWrapper p-5" onClick={() => handleBoxClick(event.title, event.date)}>
                  {loadingEventTitles[event.title] && (
                    <div className="loadingReq text-center">
                      <h4>
                        <span className="flip-emoji">✍️</span>
                        <span className="flip-emoji">😃</span>
                      </h4>
                      <p className="typing-text">
                        Writing post
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </p>
                    </div>
                  )}
                  <div className="eventBox px-5 flex flex-col justify-between h-full">
                    <div className="dayMetaInfo">
                      <h2 className="eventTitle mb-3">{event.title}</h2>
                      <p className="eventContent">
                        {loadingEventTitles[event.title] ? (
                          "Loading..."
                        ) : (
                          <div
                            className="eventContent"
                            dangerouslySetInnerHTML={{
                              __html: eventContent[event.title] || "",
                            }}
                          />
                        )}
                      </p>
                      {eventContent[event.title] === undefined && (
                        <p className="eventHashtag">{event.hashtags}</p>
                      )}
                    </div>
                    <div className="dayActions pb-2">
                      {eventContent[event.title] === undefined ? (
                        <button
                          className="writePost"
                        >
                          <SvgIcon id={"stars"} />
                          Write me a Post
                        </button>
                      ) : (
                        <div className="flex align-items-center justify-between">
                          <button
                            className="writePost"
                            onClick={() => {
                              copyEventContent(eventContent[event.title], index)
                              handleSchedule()
                            }}
                          >
                            Copy & Schedule
                          </button>
                          <button
                            className="copyContent"
                            onMouseEnter={() => setShowTooltip(index)}
                            onMouseLeave={() => setShowTooltip(null)}
                            onClick={() => copyEventContent(eventContent[event.title], index)}
                          >
                            <SvgIcon id={"copy"} />
                            {showTooltip === index && (
                              <div className="toolTipBox">
                                {tooltipStates[index] || "Copy!"}
                              </div>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};
