const icons = {
	stars: (props) => (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" fill="none">
			<path d="M11.0114 4.7907C13.3066 5.49125 15.1035 7.28908 15.8021 9.58333C16.5046 7.28812 18.3014 5.4922 20.5947 4.79166C18.3014 4.09112 16.5046 2.2952 15.8021 -3.8147e-06C15.1035 2.29425 13.3066 4.09016 11.0114 4.7907ZM17.7197 11.499C19.0969 11.9188 20.175 12.9979 20.5938 14.375C21.0154 12.9979 22.0936 11.9197 23.4697 11.5C22.0936 11.0793 21.0154 10.0021 20.5938 8.625C20.175 10.0012 19.0969 11.0793 17.7197 11.499ZM11.9697 16.7708C13.1178 17.1206 14.0158 18.0195 14.3646 19.1667C14.7154 18.0186 15.6143 17.1206 16.7614 16.7708C15.6143 16.4201 14.7163 15.5231 14.3646 14.375C14.0158 15.5221 13.1178 16.4201 11.9697 16.7708ZM6.21877 4.79166C5.38023 7.54495 3.2211 9.70312 0.469727 10.5426C3.22589 11.386 5.38023 13.5403 6.22356 16.2965C7.0621 13.5422 9.22123 11.386 11.9697 10.5426C9.22123 9.70504 7.0621 7.54591 6.21877 4.79166Z" fill="#FCFCFC" />
		</svg>
	),
	starsBlack: (props) => (
		<svg xmlns="http://www.w3.org/2000/svg" width="60" height="50" viewBox="0 0 60 50" fill="none">
			<path d="M27.5 12.4975C33.4875 14.325 38.175 19.015 39.9975 25C41.83 19.0125 46.5175 14.3275 52.5 12.5C46.5175 10.6725 41.83 5.9875 39.9975 0C38.175 5.985 33.4875 10.67 27.5 12.4975ZM45 29.9975C48.5925 31.0925 51.405 33.9075 52.4975 37.5C53.5975 33.9075 56.41 31.095 60 30C56.41 28.9025 53.5975 26.0925 52.4975 22.5C51.405 26.09 48.5925 28.9025 45 29.9975ZM30 43.75C32.995 44.6625 35.3375 47.0075 36.2475 50C37.1625 47.005 39.5075 44.6625 42.5 43.75C39.5075 42.835 37.165 40.495 36.2475 37.5C35.3375 40.4925 32.995 42.835 30 43.75ZM14.9975 12.5C12.81 19.6825 7.1775 25.3125 0 27.5025C7.19 29.7025 12.81 35.3225 15.01 42.5125C17.1975 35.3275 22.83 29.7025 30 27.5025C22.83 25.3175 17.1975 19.685 14.9975 12.5Z" fill="#0D0D0D" />
		</svg>
	),
	copy: (props) => (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<g clip-path="url(#clip0_208_189)">
				<path d="M18.0408 5V18.3333H4.92021V5H18.0408ZM19.6809 3.33333H3.28014V20H19.6809V3.33333ZM0 17.5V0H17.2207V1.66667H1.64007V17.5H0Z" fill="#0D0D0D" />
			</g>
			<defs>
				<clipPath id="clip0_208_189">
					<rect width="19.6809" height="20" fill="white" />
				</clipPath>
			</defs>
		</svg>
	)
};

// name: (props) => (),

export default icons;
