import { useState } from "react";
import { HolidayEvent } from "../../../../pages/calendar";
import SvgIcon from "../icons";
import "./style.css";
import { useHttp } from "../../../../common/hooks/useHttp";
import UserModal from "../UserModal";
import localforage from "localforage";
import env from "../../../../config";
import LimitModal from "../LimitModal";

interface DayProps {
  date: Date;
  events: HolidayEvent[];
  handleLocation: any;
  locationValid: Boolean;
}
interface UserData { username: string, loc_name: string, email: string, role: string }

export const Day: React.FC<DayProps> = ({
  date,
  events,
  handleLocation,
  locationValid,
}) => {
  const BASE_URL = `${env.API_URL}`;
  const APP_ENV = env.APP_ENV;
  const [loadingEventTitles, setLoadingEventTitles] = useState<{ [key: string]: boolean }>({});
  const [eventContent, setEventContent] = useState<{
    [key: string]: string | null;
  }>({});
  const [tooltipStates, setTooltipStates] = useState<any>({});
  const [showTooltip, setShowTooltip] = useState<number | null>(null);
  const { sendRequest: getEventContent } = useHttp();
  const { sendRequest: updateUser } = useHttp();
  const dayOfWeek = date.toLocaleString("en-US", { weekday: "short" });
  const dayOfMonth = date.getDate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLimitModalOpen, setIsLimitModalOpen] = useState(false);
  const currentDate = new Date().getDate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeLimit_withRedirect = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/calendar-upgrade-social', '_self');
  };

  const closeLimitModel = () => {
    setIsLimitModalOpen(false);
    window.open('https://go.maybetech.com/masterclass', '_self');
  };

  const handleRequest = async (title: string, date: string) => {
    if (loadingEventTitles[title]) {
      return;
    }

    setLoadingEventTitles((prevLoadingEventTitles) => ({
      ...prevLoadingEventTitles,
      [title]: true,
    }));

    const user_data: UserData | null = await localforage.getItem("user_data");
    const calendar_res: number | null = await localforage.getItem("calendar_res");

    if (calendar_res !== null && calendar_res >= 10) {
      setIsLimitModalOpen(true);
      setLoadingEventTitles((prevLoadingEventTitles) => ({
        ...prevLoadingEventTitles,
        [title]: false,
      }));
      return false;
    }

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    if (user_data) {
      updateUser(
        {
          url: `${BASE_URL}/user/updateuser?email=${encodeURIComponent(user_data?.email)}`,
          method: "PUT",
          payload: {},
        },
        async (response: any) => {
          await localforage.setItem('calendar_res', response.data.calendar_res)
          getEventContent(
            {
              url: `${BASE_URL}/chat/dayevent`,
              method: "POST",
              payload: { date: date, event_title: title, user_name: user_data?.username, env: APP_ENV },
            },
            (response: any) => {
              setEventContent((prevContent) => ({
                ...prevContent,
                [title]: response.data,
              }));

              setLoadingEventTitles((prevLoadingEventTitles) => ({
                ...prevLoadingEventTitles,
                [title]: false,
              }));
            }
          );
        }
      );
    } else {
      getEventContent(
        {
          url: `${BASE_URL}/chat/dayevent`,
          method: "POST",
          payload: { date: date, event_title: title, env: APP_ENV },
        },
        (response: any) => {
          setEventContent((prevContent) => ({
            ...prevContent,
            [title]: response.data,
          }));
          setLoadingEventTitles((prevLoadingEventTitles) => ({
            ...prevLoadingEventTitles,
            [title]: false,
          }));
        }
      )
    }
  };

  const copyEventContent = (content: string | null, eventIndex: number) => {
    if (content == null) return false;
    const tempEl = document.createElement("div");
    tempEl.innerHTML = content;

    // Append the temporary element to the body
    document.body.appendChild(tempEl);

    // Create a range and select the content of the temporary element
    const range = document.createRange();
    range.selectNodeContents(tempEl);

    // Remove any current selections and add the new range
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);

    // Copy the selected content to the clipboard
    try {
      document.execCommand("copy");
      setTooltipStates((prevStates: any) => ({
        ...prevStates,
        [eventIndex]: "Copied!",
      }));
    } catch (err) {
      console.error("Failed to copy! ", err);
    }

    // Clear the selection and remove the temporary element
    selection?.removeAllRanges();
    tempEl.remove();

    setTimeout(() => {
      setTooltipStates((prevStates: any) => ({
        ...prevStates,
        [eventIndex]: "Copy!",
      }));
    }, 1500);
  };

  const handleBoxClick = (title: string, date: string) => {
    if (eventContent[title] === undefined) {
      handleRequest(title, date);
    }
  };

  return (
    <div className="mt-8 flex flex-wrap">
      <div
        className="dayDate flex flex-col items-start mb-4 w-[5%]"
        id={currentDate.toString() == dayOfMonth.toString() ? "focus" : ""}
      >
        <h2>{dayOfMonth}</h2>
        <p>{dayOfWeek.toUpperCase()}</p>
      </div>
      <LimitModal
        isModalOpen={isLimitModalOpen}
        closeModalWithRedirect={closeLimit_withRedirect}
        justCloseIt={closeLimitModel}
      />
      <UserModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        handleLocation={handleLocation}
      />
      <div className="dayEvents w-[95%]">
        {events.length > 0 ? (
          <div className="flex flex-wrap w-full">
            {events.map((event, index) => (
              <div key={index} className="w-full md:w-1/3 mb-4 p-5 pt-0 pl-0">
                <div className="eventBoxWrapper p-5" onClick={() => handleBoxClick(event.title, event.date)}>
                  {loadingEventTitles[event.title] && (
                    <div className="loadingReq text-center">
                      <h4>
                        <span className="flip-emoji">✍️</span>
                        <span className="flip-emoji">😃</span>
                      </h4>
                      <p className="typing-text">
                        Writing post
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </p>
                    </div>
                  )}
                  <div className="eventBox px-5 flex flex-col justify-between h-full">
                    <div className="dayMetaInfo">
                      <h2 className="eventTitle mb-3">{event.title}</h2>
                      <p className="eventContent">
                        {loadingEventTitles[event.title] ? (
                          "Loading..."
                        ) : (
                          <div
                            className="eventContent"
                            dangerouslySetInnerHTML={{
                              __html: eventContent[event.title] || "",
                            }}
                          />
                        )}
                      </p>
                      {eventContent[event.title] === undefined && (
                        <p className="eventHashtag">{event.hashtags}</p>
                      )}
                    </div>
                    <div className="dayActions pb-2">
                      {eventContent[event.title] === undefined ? (
                        <button
                          className="writePost"
                        >
                          <SvgIcon id={"stars"} />
                          Write me a Post
                        </button>
                      ) : (
                        <div className="flex align-items-center justify-between">
                          {/* isModalOpen, closeModal */}
                          {locationValid ? (
                            <></>
                          ) : (
                            <button className="personalise" onClick={openModal}>
                              <SvgIcon id={"stars"} />
                              Personalise
                            </button>
                          )}
                          {/* <button className="writePost">Schedule</button> */}
                          <button
                            className="copyContent"
                            onMouseEnter={() => setShowTooltip(index)}
                            onMouseLeave={() => setShowTooltip(null)}
                            onClick={() =>
                              copyEventContent(eventContent[event.title], index)
                            }
                          >
                            <SvgIcon id={"copy"} />
                            {showTooltip === index && (
                              <div className="toolTipBox">
                                {tooltipStates[index] || "Copy!"}
                              </div>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};