import React, { useState } from "react";
import SvgIcon from "../icons";
import "./index.css";
import axios from "axios";
import localforage from "localforage";
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: any;
}
interface handleProps {
  isModalOpen: boolean;
  closeModalWithRedirect: () => void;
  justCloseIt: () => void;
}
const BASE_URL = `https://apichat.maybetech.com/api/v1`;

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <div className="modal-overlay" onClick={onClose}>
          <div
            className="modal-content text-center"
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

// Example usage in another component
const LimitModal: React.FC<handleProps> = ({
  isModalOpen,
  closeModalWithRedirect,
  justCloseIt,
}) => {
  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={justCloseIt}>
        <p className="modalText">
        📅 You're a Social Media Star! 📅
        </p>
        <p className="modalDesc">
          Oops! You've used up all of your free goes. <br/>
          Don't worry, your social media journey doesn't have to end here. 🌟
        </p>
        <div className="listBox">
          <p className="listBoxHeading">Upgrade Now for:</p>
          <ul>
            <li>📅 Unlimited Content Inspiration</li>
            <li>🤖 AI Smart Scheduling</li>
            <li>💬 Advanced Engagement Features</li>
            <li>📊 Awesome Ads and Automation Tools</li>
            <li>🌐 All-in-One Platform Management</li>
          </ul>
        </div>
        <button type="submit" className="modalBtn" onClick={closeModalWithRedirect}>
          Yes, I’m ready to upgrade
        </button> 
        <p className="notYet" onClick={justCloseIt}>Not yet, I’ll take the free Masterclass</p>
      </Modal>
    </div>
  );
};

export default LimitModal;
