import { Day } from "./components/day";
import "./style.css";
import maybeLogo from "../../assets/icons/maybe-logo.webp"
import { useEffect, useState } from "react";
import axios from "axios";
import localforage from "localforage";
import env from "../../config";
import { useHttp } from "../../common/hooks/useHttp";
export interface HolidayEvent {
  date: string;
  title: string;
  content: string;
  country: string;
  event_categories: string;
  event_url?: string;
  hashtags?: string;
  emoji: string;
}

export interface EventData {
  holidays: HolidayEvent[];
}

interface EventsByDay {
  [key: string]: HolidayEvent[];
}

interface EventsByMonth {
  [key: number]: EventsByDay;
}

function FreeTrial() {
  const BASE_URL = `${env.API_URL}`;
  const [eventsByMonth, setEventsByMonth] = useState<EventsByMonth>({});
  const [locationValid, setlocationValid] = useState(false);
  const [eventData, setEventData] = useState<EventData | null>(null);
  const { sendRequest: getEventData } = useHttp();
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState<number>(
    new Date().getMonth()
  );

  useEffect(() => {
    getEventData(
      {
        url: `${BASE_URL}/chat/event-data`,
        method: "GET",
        payload: {},
      },
      async (response: any) => {
        setEventData(response.data as EventData);
        setLoading(false);
        console.log(response);
      }
    );
  }, [])

  useEffect(() => {
    // Check if eventData is defined before processing
    if (eventData && !loading) {
      const organizedData: EventsByMonth = {};
      eventData?.holidays.forEach((event: any) => {
        const date = new Date(event.date);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
          return;
        }

        const month = date.getMonth();
        const day = date.getDate().toString();

        organizedData[month] = organizedData[month] || {};
        organizedData[month][day] = organizedData[month][day] || [];
        organizedData[month][day].push(event);
      });

      setEventsByMonth(organizedData);

      setTimeout(() => {
        const specificElement = document.getElementById("focus");

        if (specificElement) {
          specificElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
      }, 1000);
    }
  }, [eventData]);

  // Get the current year and month
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  const checkLocation = async (user_data: any) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${env.PANDORA_API}/assistants/${user_data._id}`,
      headers: {
        'Authorization': `Bearer ${env.PANDORA_AUTH}`,
      },
    };

    await axios
      .request(config)
      .then((response) => {
        if (response?.data?.data?.length > 0) {
          setlocationValid(true);
          return {
            success: true,
            status: 200,
          };
        } else {
          setTimeout(() => {
            window.location.href = `https://connect.maybetech.com/?profile_id=${user_data._id}&profile_name=${user_data.loc_name}&username=${user_data.username}&type=trial_calendar`;
          }, 2000)
          return {
            success: true,
            status: 404,
          };
        }
      })
      .catch((error) => {
        window.location.href = `https://connect.maybetech.com/?profile_id=${user_data._id}&profile_name=${user_data.loc_name}&username=${user_data.username}&type=trial_calendar`;
      });
  };
  const renderDays = () => {
    const daysInMonth = new Date(currentYear, selectedMonth + 1, 0).getDate();

    return Array.from({ length: daysInMonth }, (_, i) => {
      const dayDate = new Date(currentYear, selectedMonth, i + 1);
      const day = dayDate.toISOString().split("T")[0];

      const events =
        eventsByMonth[selectedMonth] && eventsByMonth[selectedMonth][i + 1]
          ? eventsByMonth[selectedMonth][i + 1]
          : [];

      return (
        <>
          <Day
            key={i}
            date={dayDate}
            events={events}
            handleLocation={checkLocation}
            locationValid={locationValid}
          />
          <hr />
        </>
      );
    });
  };

  const handleMonthClick = (month: number) => {
    setSelectedMonth(month);
  };

  const renderMonthTabs = () => {
    const months = Array.from({ length: 12 }, (_, i) => i);

    return months.map((month) => (
      <div
        key={month}
        className={`monthTabsItem pb-2 ${selectedMonth === month ? "activeTab" : ""
          }`}
        onClick={() => handleMonthClick(month)}
      >
        <h2>
          {new Date(2022, month, 1).toLocaleString("en-US", { month: "short" })}
        </h2>
      </div>
    ));
  };

  const onLoad = async () => {
    const user_data = await localforage.getItem("user_data");
    if (user_data) {
      checkLocation(user_data);
    }
  };
  useEffect(() => {
    onLoad();
  }, []);
  return (
    <div className="p-5 m-auto">
      <div className="topHeader">
        <div className="logo">
          <img src={maybeLogo} alt="" />
        </div>
        <div className="groupBtn">
          <a href="https://go.maybetech.com/calendar-upgrade-social" target="_blank" className="black">
            Personlise my results
          </a>
          <a href="https://v2.maybetech.com/"  target="_blank">
            Log in
          </a>
        </div>
      </div>
      <div className="calendarWrapper">
        <div id="month-bar">
          <h1 className="year mb-5">{currentYear}</h1>
          <div className="monthTabs w-[90%] m-auto flex justify-between">
            {renderMonthTabs()}
          </div>
          <h2 className="fullMonth">
            {new Date(2022, selectedMonth, 1)
              .toLocaleString("en-US", {
                month: "long",
              })
              .toUpperCase()}
          </h2>
        </div>
        {renderDays()}
      </div>
    </div>
  );
}

export default FreeTrial;
